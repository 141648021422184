import $backend from "@ef/global/backend";
import { checkStatus } from "@ef/global/helpers";
export default {
  URL: "batches/",

  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("teachers_get_batches", { params: { course_id: courseId }, ...opts })
      .then(checkStatus)
      .then(r => r.data.content.batches);
  },
  /**
   * @return Promise
   * @param batch
   * @param headers
   */
  save(courseId, batch, headers = {}) {
    return $backend
      .post("teachers_post_batches", { course_id: courseId, ...batch }, headers)
      .then(checkStatus)
      .then(r => r.data.content);
  },
};
