import axios from "axios";
import { delayPromise } from "@ef/global/helpers";
import _ from "lodash";

const API_URL = process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL;

let $axios = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});

async function custom_get(channel, data = {}) {
  const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
  const requestId = `${channel}-${rn}`;
  let payload = !_.isEmpty(data.params) ? data.params : "";
  if (process.env.VUE_APP_RUN_ENV === "development")
    // eslint-disable-next-line
  await new Promise((resolve, reject) => {
      let oReq = new XMLHttpRequest();
      oReq.open("POST", API_URL);
      oReq.setRequestHeader("Channel-Name", channel);
      oReq.setRequestHeader("Request-Id", requestId);
      oReq.setRequestHeader("Content-Type", "application/msgpack");
      oReq.setRequestHeader(
        "Authorization",
        $axios.defaults.headers["Authorization"],
      );
      oReq.onload = resolve;
      oReq.onerror = reject;
      oReq.send(payload);
    });
  await delayPromise(1000);
  return $axios.get("", {
    headers: {
      "Channel-Name": channel,
      "Request-Id": requestId,
    },
  });
}

async function custom_post(channel, data = {}, _options = {}) {
  const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
  const requestId = `${channel}-${rn}`;
  let payload = !_.isEmpty(data) ? data : "";
  if (process.env.VUE_APP_RUN_ENV === "development")
    // eslint-disable-next-line

    await new Promise((resolve, reject) => {
      let oReq = new XMLHttpRequest();
      oReq.open("POST", API_URL);
      oReq.setRequestHeader("Channel-Name", channel);
      oReq.setRequestHeader("Request-Id", requestId);
      oReq.setRequestHeader("Content-Type", "application/msgpack");
      oReq.setRequestHeader(
        "Authorization",
        $axios.defaults.headers["Authorization"],
      );
      oReq.onload = resolve;
      oReq.onerror = reject;
      oReq.send(payload);
    });

  // oReq.onload = () {
  //   if (this.status >= 200 && this.status < 300) {
  //     resolve(oReq.response);
  //   } else {
  //     reject({
  //       status: this.status,
  //       statusText: oReq.statusText
  //     });
  //   }
  // };
  // oReq.onerror = () {
  //   reject({
  //     status: this.status,
  //     statusText: oReq.statusText
  //   });
  // };
  // await $axios.post("", payload, {
  //   headers: {
  //     "Channel-Name": channel,
  //     "Request-Id": requestId,
  //     "Content-Type": "application/msgpack"
  //   }
  // });
  await delayPromise(10000);
  return $axios.get("", {
    headers: {
      "Channel-Name": channel,
      "Request-Id": requestId,
    },
  });
}

export default {
  post: custom_post,
  apost: $axios.post,
  get: custom_get,
  put: $axios.put,
  delete: $axios.delete,
  headers: $axios.defaults.headers,
  interceptors: $axios.interceptors,
  all: axios.all,
  domain: API_URL,
};
