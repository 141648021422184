<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0" cols="12">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" :cols="colNum">
          <div
            v-if="isProjectOrAssignment"
            class="mk-base-card-middle-content-element_1 text-truncate"
          >
            {{ !$language ? "Due Date" : "শেষ তারিখ" }} :
            {{
              !$language
                ? element_1
                : new Date(element_1).toLocaleString("bn-BD", {
                    dateStyle: "medium",
                  })
            }}
          </div>
          <div
            v-else
            class="mk-base-card-middle-content-element_1 text-truncate"
          >
            {{ !$language ? "Available Upto" : "পরীক্ষা দেওয়ার সর্বশেষ তারিখ" }}
            :
            {{
              !$language
                ? element_1
                : new Date(element_1).toLocaleString("bn-BD", {
                    dateStyle: "medium",
                  })
            }}
          </div>
        </v-col>
        <v-col class="ma-0 pa-0" :cols="12 - colNum">
          <div
            v-if="isProjectOrAssignment"
            class="mk-base-card-middle-content-element_2 text-truncate"
          >
            <b>{{ !$language ? "Status" : "স্ট্যাটাস" }}</b> :
            {{ translateEL2BN }}
          </div>

          <div
            v-else
            class="mk-base-card-middle-content-element_2 text-truncate"
          >
            <b>{{ !$language ? "Duration" : "পরীক্ষার সময়" }} </b> :
            {{ element_2 }} {{ !$language ? "Min." : "মিনিট" }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="ma-0 pa-0" cols="12">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" :cols="colNum">
          <div class="mk-base-card-middle-content-element_3 text-truncate">
            <b>{{ !$language ? "Total Mark" : "মোট নাম্বার" }} : </b
            >{{ element_3 }}
          </div>
        </v-col>
        <v-col class="ma-0 pa-0" :cols="12 - colNum">
          <div
            v-if="isProjectOrAssignment"
            class="mk-base-card-middle-content-element_4 text-truncate"
          >
            <b>{{ !$language ? "Score" : "স্কোর" }}</b> : {{ translateEL4BN }}
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
cz
<script>
export default {
  name: "MBaseCardMiddleContent",
  props: {
    element_1: String,
    element_2: String,
    element_3: Number,
    element_4: String,
    element_type: String,
  },
  data() {
    return {};
  },
  computed: {
    translateEL2BN() {
      if (this.$language) {
        if (this.element_2 == "Submitted") return "জমা দেওয়া হয়েছে";
        else return "জমা দেওয়া হয় নি";
      } else return this.element_2;
    },
    translateEL4BN() {
      if (this.$language) {
        if (this.element_4 === "Not published") return "প্রকাশিত হয়নি";
        else if (this.element_4 === "Not Submitted") return "জমা দেওয়া হয় নি";
        else return this.element_4;
      } else return this.element_4;
    },
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },

    isProjectOrAssignment() {
      return (
        this.element_type == "projcet" || this.element_type == "assignment"
      );
    },

    colNum() {
      return this.currentWidth < 800
        ? 7
        : this.currentWidth < 1280
        ? 7
        : this.currentWidth < 1920
        ? 7
        : 7;
    },
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.mk-base-card-middle-content-element_1 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  text-align: left;
  color: #fc6222;
  font-weight: normal;
  // For Small Screen
  @media screen and (max-width: 799px) {
    font-size: 10px;
    letter-spacing: 0px;
    padding-left: 17px;
    padding-top: 15px;
    max-width: 100%;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    font-size: 14px;
    letter-spacing: 0px;
    padding-left: 30px;
    padding-top: 25px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0px;
    padding-left: 25px;
    padding-top: 25px;
    max-width: 100%;
  }
}

.mk-base-card-middle-content-element_2 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  text-align: left;
  color: #202020;
  font-weight: normal;
  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0px;
    font-size: 10px;
    padding-right: 13px;
    padding-top: 15px;
    max-width: 100%;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-right: 30px;
    padding-top: 25px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-right: 22px;
    padding-top: 25px;
    max-width: 100%;
  }
}

.mk-base-card-middle-content-element_3 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  color: #202020;
  text-align: left;
  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0px;
    font-size: 10px;
    padding-top: 5px;
    padding-left: 17px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-left: 30px;
    padding-top: 12px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-left: 25px;
    padding-top: 12px;
    max-width: 100%;
  }
}

.mk-base-card-middle-content-element_4 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  color: #202020;
  text-align: left;
  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0px;
    font-size: 10px;
    padding-top: 5px;
    padding-right: 13px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-right: 30px;
    padding-top: 12px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    color: #707070;
    letter-spacing: 0px;
    font-size: 14px;
    padding-right: 22px;
    padding-top: 12px;
    max-width: 100%;
  }
}
</style>
