var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "mx-0 mt-0 py-0 pr-0",
      style: _vm.is_small ? "margin-bottom:35px" : "margin-bottom:auto",
      attrs: { cols: "12" },
    },
    [
      _c(
        "div",
        { staticClass: "mk-page-title-search-create-section" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "mk-batch-page-title pt-0 pb-0 ma-0",
                  attrs: { cols: _vm.colNumTitle },
                },
                [_c("h1", [_vm._v(_vm._s(_vm.pageTitle))])]
              ),
              _c(
                "v-col",
                {
                  class: [
                    "pl-0",
                    "py-0",
                    "ma-0",
                    "mk-page-search-input-field-section",
                    _vm.forSmallScreenClass,
                  ],
                  attrs: { cols: _vm.colNumInput },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: "10" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mk-page-search-input-field",
                            attrs: {
                              flat: "",
                              solo: "",
                              placeholder: _vm.placeholder,
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.searchInput,
                              callback: function ($$v) {
                                _vm.searchInput = $$v
                              },
                              expression: "searchInput",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0 mk-page-search-btn-section",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "white--text q-search mk-page-search-btn",
                              attrs: {
                                block: "",
                                depressed: "",
                                tile: "",
                                color: "#0097D9",
                              },
                            },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v(" search "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }