<template>
  <v-card
    outlined
    :width="cardWidth"
    :height="cardHeight"
    class="mk-new-base-card"
  >
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12">
        <slot name="header"> </slot>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12">
        <slot name="middle-content"> </slot>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12">
        <slot name="footer"> </slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MBaseCardNew",
  props: {
    element_type: { type: String, default: "other" },
  },
  data() {
    return {};
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    cardWidth() {
      return this.currentWidth < 800
        ? 270
        : this.currentWidth < 1280
        ? 500
        : this.currentWidth < 1920
        ? 470
        : 500;
    },
    cardHeight() {
      return this.currentWidth < 800 && this.element_type != "lecture"
        ? 160
        : this.currentWidth >= 800 && this.element_type != "lecture"
        ? 250
        : this.currentWidth < 800
        ? 100
        : 190;
    },
    titleColNum() {
      return this.currentWidth < 800
        ? 8
        : this.currentWidth < 1280
        ? 8
        : this.currentWidth < 1920
        ? 8
        : 8;
    },
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";
.mk-new-base-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #0099dc !important;
  border-radius: 5px !important;
}
</style>
