import { render, staticRenderFns } from "./MBaseCardHeaderNew.vue?vue&type=template&id=693d9c5c"
import script from "./MBaseCardHeaderNew.vue?vue&type=script&lang=js"
export * from "./MBaseCardHeaderNew.vue?vue&type=script&lang=js"
import style0 from "./MBaseCardHeaderNew.vue?vue&type=style&index=0&id=693d9c5c&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('693d9c5c')) {
      api.createRecord('693d9c5c', component.options)
    } else {
      api.reload('693d9c5c', component.options)
    }
    module.hot.accept("./MBaseCardHeaderNew.vue?vue&type=template&id=693d9c5c", function () {
      api.rerender('693d9c5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/students-portal/core/components/MBaseCardHeaderNew.vue"
export default component.exports