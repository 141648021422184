var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0" },
    [
      _c(
        "v-col",
        { staticClass: "ma-0 pa-0", attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0", attrs: { cols: _vm.colNum } },
                [
                  _vm.isProjectOrAssignment
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mk-base-card-middle-content-element_1 text-truncate",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language ? "Due Date" : "শেষ তারিখ"
                              ) +
                              " : " +
                              _vm._s(
                                !_vm.$language
                                  ? _vm.element_1
                                  : new Date(_vm.element_1).toLocaleString(
                                      "bn-BD",
                                      { dateStyle: "medium" }
                                    )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "mk-base-card-middle-content-element_1 text-truncate",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language
                                  ? "Available Upto"
                                  : "পরীক্ষা দেওয়ার সর্বশেষ তারিখ"
                              ) +
                              " : " +
                              _vm._s(
                                !_vm.$language
                                  ? _vm.element_1
                                  : new Date(_vm.element_1).toLocaleString(
                                      "bn-BD",
                                      { dateStyle: "medium" }
                                    )
                              ) +
                              " "
                          ),
                        ]
                      ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0", attrs: { cols: 12 - _vm.colNum } },
                [
                  _vm.isProjectOrAssignment
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mk-base-card-middle-content-element_2 text-truncate",
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(!_vm.$language ? "Status" : "স্ট্যাটাস")
                            ),
                          ]),
                          _vm._v(" : " + _vm._s(_vm.translateEL2BN) + " "),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "mk-base-card-middle-content-element_2 text-truncate",
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language ? "Duration" : "পরীক্ষার সময়"
                              ) + " "
                            ),
                          ]),
                          _vm._v(
                            " : " +
                              _vm._s(_vm.element_2) +
                              " " +
                              _vm._s(!_vm.$language ? "Min." : "মিনিট") +
                              " "
                          ),
                        ]
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "ma-0 pa-0", attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0", attrs: { cols: _vm.colNum } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mk-base-card-middle-content-element_3 text-truncate",
                    },
                    [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            !_vm.$language ? "Total Mark" : "মোট নাম্বার"
                          ) + " : "
                        ),
                      ]),
                      _vm._v(_vm._s(_vm.element_3) + " "),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0", attrs: { cols: 12 - _vm.colNum } },
                [
                  _vm.isProjectOrAssignment
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mk-base-card-middle-content-element_4 text-truncate",
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(!_vm.$language ? "Score" : "স্কোর")),
                          ]),
                          _vm._v(" : " + _vm._s(_vm.translateEL4BN) + " "),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }