var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0" },
    [
      _c(
        "v-col",
        { staticClass: "ma-0 pa-0", attrs: { cols: _vm.colNum } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c("v-col", { staticClass: "ma-0 pa-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "mk-base-card-header-element_1 text-truncate",
                  },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                ),
              ]),
              _c("v-col", { staticClass: "ma-0 pa-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "mk-base-card-header-element_2 text-truncate",
                  },
                  [_vm._v(" " + _vm._s(_vm.availableDate) + " ")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isProjectOrAssignment
        ? _c(
            "v-col",
            {
              staticClass: "ma-0 pa-0 d-flex justify-end",
              attrs: { cols: 12 - _vm.colNum },
            },
            [
              _vm.smallScreen
                ? _c(
                    "div",
                    {
                      staticClass: "mk-base-card-header-element_3",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("header_element_3")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.btnName) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "mk-base-card-header-element_3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", tile: "", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("header_element_3")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.btnName) + " ")]
                      ),
                    ],
                    1
                  ),
            ]
          )
        : _vm.isExam
        ? _c(
            "v-col",
            {
              staticClass: "ma-0 pa-0 d-flex justify-end",
              attrs: { cols: 12 - _vm.colNum },
            },
            [
              _vm.smallScreen
                ? _c(
                    "div",
                    {
                      staticClass: "mk-base-card-header-element_3",
                      style: _vm.shouldEnableElement_3
                        ? { "pointer-events": "auto" }
                        : {
                            "pointer-events": "none",
                            color: "rgba(0, 0, 0, 0.5)",
                          },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("header_element_3")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.btnName) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "mk-base-card-header-element_3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.shouldEnableElement_3,
                            depressed: "",
                            tile: "",
                            plain: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("header_element_3")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.btnName) + " ")]
                      ),
                    ],
                    1
                  ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }