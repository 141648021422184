<template>
  <v-col
    cols="12"
    class="mx-0 mt-0 py-0 pr-0"
    :style="is_small ? 'margin-bottom:35px' : 'margin-bottom:auto'"
  >
    <div class="mk-page-title-search-create-section">
      <v-row>
        <v-col :cols="colNumTitle" class="mk-batch-page-title pt-0 pb-0 ma-0"
          ><h1>{{ pageTitle }}</h1>
        </v-col>
        <v-col
          :cols="colNumInput"
          :class="[
            'pl-0',
            'py-0',
            'ma-0',
            'mk-page-search-input-field-section',
            forSmallScreenClass,
          ]"
        >
          <v-row>
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
                v-model="searchInput"
                flat
                solo
                :placeholder="placeholder"
                hide-details
                class="mk-page-search-input-field"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 mk-page-search-btn-section"
              ><v-btn
                block
                depressed
                tile
                class="white--text q-search mk-page-search-btn"
                color="#0097D9"
              >
                <span class="material-icons"> search </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "PageTitleSearch",
  props: {
    pageTitle: String,
    placeholder: String,
  },
  data() {
    return {
      searchInput: "",
    };
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },

    is_small() {
      return this.currentWidth < 1280;
    },
    forSmallScreenClass() {
      return this.is_small ? "mk-page-search-input-field-section-small" : "";
    },
    colNumTitle() {
      return this.currentWidth < 1280 ? 12 : 3;
    },

    colNumInput() {
      return this.currentWidth < 1280 ? 12 : 9;
    },
  },
  watch: {
    searchInput: {
      handler(newValue) {
        this.$emit("searchElenent", newValue);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.custom-font-family,
.custom-font-family.v-card__title,
button.custom-font-family {
  font-family: "Roboto", "Helvetica", "sans-serif" !important;
}
.mk-page-title-search-create-section .v-text-field,
.mk-page-title-search-create-section .v-input__control,
.mk-page-title-search-create-section .v-input__slot,
.mk-page-title-search-create-section .v-text-field__slot {
  height: 100%;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.mk-page-search-input-field-section {
  // For larger screen
  @media screen and (min-width: 1920px) {
    padding-right: 15%;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding-right: 20%;
  }

  // For tab
  @media screen and (max-width: 1279px) {
    padding-right: 0%;
  }
}

.mk-page-search-input-field-section.mk-page-search-input-field-section-small {
  padding-top: 10px !important;
  // For tab
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    max-width: 500px;
    padding-left: 23px !important;
    padding-right: 3px !important;
  }
  /* For normal Screen */
  @media screen and (max-width: 799px) {
    max-width: 270px;
    padding-left: 23px !important;
    padding-right: 14px !important;
  }
}

.mk-page-search-input-field {
  border: 0.5px solid black;
  // For larger screen
  @media screen and (min-width: 1920px) {
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
  }

  // For tab
  @media screen and (max-width: 1279px) {
  }
}

.mk-page-title-search-create-section {
  // For larger screen
  @media screen and (min-width: 1920px) {
    margin-top: 18px;
    margin-bottom: 37px;
    height: 55px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    margin-top: 18px;
    margin-bottom: 23px;
    height: 50px;
  }

  // For tab
  @media screen and (max-width: 1279px) {
    margin-top: 18px;
    height: 40px;
    max-width: 550px;
    margin-bottom: 20px;
  }
}

.mk-page-search-btn {
  // For larger screen
  @media screen and (min-width: 1920px) {
    height: 53px !important;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    height: 53px !important;
  }

  // For tab
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    height: 43px !important;
  }

  /* For normal Screen */
  @media screen and (max-width: 799px) {
    height: 37px !important;
  }
}

.mk-page-search-icon {
  // For larger screen
  @media screen and (min-width: 1920px) {
    height: 55px;
    width: 100%;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    height: 50px;
    width: 100%;
  }

  // For tab
  @media screen and (max-width: 1279px) {
    height: 40px;
    width: 100%;
  }
}

.mk-batch-page-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // For larger screen
  @media screen and (min-width: 1920px) {
    padding-right: 0px !important;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding-right: 0px !important;
  }
}

.mk-batch-page-title > h1 {
  // For larger screen
  @media screen and (min-width: 1920px) {
    width: 39px;
    font-size: 30px;
    letter-spacing: 0.9px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    width: 33px;
    font-size: 25px;
    letter-spacing: 0.75px;
  }

  // For tab
  @media screen and (max-width: 1279px) {
    width: 26px;
    font-size: 20px;
    letter-spacing: 0.6px;
  }
}

.mk-page-add-btn {
  // For larger screen
  @media screen and (min-width: 1920px) {
    width: 95%;
    height: 55px;
  }

  /* For normal Screen */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    width: 95%;
    height: 50px;
    font-size: 18px;
  }

  // For tab
  @media screen and (max-width: 1279px) {
    width: 82%;
    height: 40px;
  }
}

.mk-page-title-search-create-section
  .v-text-field.v-text-field--solo
  .v-input__control {
  // For tab
  @media screen and (min-width: 800px) and (max-width: 1279px) {
    min-height: 40px;
    font-size: 12px;
  }

  /* For normal Screen */
  @media screen and (max-width: 799px) {
    min-height: 35px;
    font-size: 10px;
  }
}
</style>
