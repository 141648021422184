var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0" },
    [
      !_vm.is_lecture_enable
        ? _c("v-col", { staticClass: "ma-0 pa-0", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "mk-base-card-footer-element_1" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      loading: _vm.lodingElement_1,
                      depressed: "",
                      tile: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("footer_element_1")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.element_1) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mk-base-card-footer-element_2" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      loading: _vm.lodingElement_2,
                      disabled: _vm.shouldEnableElement_2,
                      depressed: "",
                      tile: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("footer_element_2")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.element_2) + " ")]
                ),
              ],
              1
            ),
          ])
        : _c("v-col", { staticClass: "ma-0 pa-0", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                class: [
                  "mk-base-card-footer-element_1",
                  _vm.add_play_or_preview_class,
                ],
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { depressed: "", tile: "", plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("footer_element_1")
                      },
                    },
                  },
                  [
                    _vm.is_play_btn_active
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { loading: _vm.lodingElement_1 },
                          },
                          [_vm._v("fa-solid fa-play ")]
                        )
                      : _c("v-icon", { staticClass: "pr-1" }, [
                          _vm._v("fa-solid fa-file"),
                        ]),
                    _vm._v(" " + _vm._s(_vm.element_1) + " "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }