<template>
  <v-row class="ma-0 pa-0">
    <v-col v-if="!is_lecture_enable" class="ma-0 pa-0" cols="12">
      <div class="mk-base-card-footer-element_1">
        <v-btn :loading="lodingElement_1" depressed tile @click="$emit('footer_element_1')">
          {{ element_1 }}
        </v-btn>
      </div>
      <div class="mk-base-card-footer-element_2">
        <v-btn
          :loading="lodingElement_2"
          :disabled="shouldEnableElement_2"
          depressed
          tile
          @click="$emit('footer_element_2')"
        >
          {{ element_2 }}
        </v-btn>
      </div>
    </v-col>
    <v-col v-else class="ma-0 pa-0" cols="12">
      <div
        :class="['mk-base-card-footer-element_1', add_play_or_preview_class]"
      >
        <v-btn depressed tile plain @click="$emit('footer_element_1')">
          <v-icon v-if="is_play_btn_active" :loading="lodingElement_1" class="pr-1"
            >fa-solid fa-play
          </v-icon>
          <v-icon v-else class="pr-1">fa-solid fa-file</v-icon> {{ element_1 }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MBaseCardFooter",
  props: {
    element_1: { type: String, default: "other" },
    element_2: { type: String, default: "other" },
    lodingElement_1: {type: Boolean, default: false},
    lodingElement_2: {type: Boolean, default: false},
    shouldEnableElement_2: {
      type: Boolean,
      default: false,
    },
    element_type: { type: String, default: "other" },
  },
  data() {
    return {};
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    is_lecture_enable() {
      return this.element_type == "lecture" ? true : false;
    },
    is_play_btn_active() {
      return this.is_lecture_enable && this.element_1 == "Play" ? true : false;
    },
    add_play_or_preview_class() {
      return this.is_play_btn_active
        ? "mk-base-card-footer-element_1_play"
        : "mk-base-card-footer-element_1_preview";
    },
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.mk-base-card-footer-element_1 {
  // For Small Screen
  @media screen and (max-width: 799px) {
    width: 75px;
    height: 30px;
    display: inline-block;
    margin-left: 17px;
    margin-top: 20px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 30px;
    margin-top: 35px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 25px;
    margin-top: 35px;
  }
}

//For lecture card

.mk-base-card-footer-element_1_play {
  // For Small Screen
  @media screen and (max-width: 799px) {
    width: 75px;
    height: 30px;
    display: inline-block;
    margin-left: 17px;
    margin-top: 10px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 30px;
    margin-top: 45px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 25px;
    margin-top: 45px;
  }
}

.mk-base-card-footer-element_1_preview {
  // For Small Screen
  @media screen and (max-width: 799px) {
    width: 88px;
    height: 30px;
    display: inline-block;
    margin-left: 17px;
    margin-top: 10px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    width: 180px;
    height: 50px;
    display: inline-block;
    margin-left: 30px;
    margin-top: 45px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    width: 180px;
    height: 50px;
    display: inline-block;
    margin-left: 25px;
    margin-top: 45px;
  }
}

.mk-base-card-footer-element_1_preview .v-icon,
.mk-base-card-footer-element_1_play .v-icon {
  // For Small Screen
  @media screen and (max-width: 799px) {
    font-size: 16px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    font-size: 24px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    font-size: 20px;
  }
}

.mk-base-card-footer-element_1.mk-base-card-footer-element_1_play > button {
  font-family: "Roboto", "Helvetica", "sans-serif";
  background: #0099dc 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  font-weight: normal;
  border: 0px;
  height: 100% !important;
  width: 100% !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    box-shadow: 0px 2px 4px #0000001a !important;
    border-radius: 5px;
    letter-spacing: 0.33px;
    font-size: 11px !important;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }
}

.mk-base-card-footer-element_1.mk-base-card-footer-element_1_preview > button {
  font-family: "Roboto", "Helvetica", "sans-serif";
  background: #0099dc 0% 0% no-repeat padding-box !important;
  border: 0px;
  color: #ffffff !important;
  font-weight: normal;
  height: 100% !important;
  width: 100% !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    box-shadow: 0px 2px 4px #0000001a !important;
    border-radius: 5px;
    letter-spacing: 0.33px;
    font-size: 11px !important;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }
}

//end For lecture

.mk-base-card-footer-element_2 {
  // For Small Screen
  @media screen and (max-width: 799px) {
    width: 75px;
    height: 30px;
    display: inline-block;
    margin-left: 17px;
    margin-top: 20px;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 25px;
    margin-top: 35px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    width: 160px;
    height: 50px;
    display: inline-block;
    margin-left: 25px;
    margin-top: 35px;
  }
}

.mk-base-card-footer-element_2 > button:disabled {
  // For Small Screen
  @media screen and (max-width: 799px) {
    border: 0.5px solid #0099dc;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    border: 1px solid #0099dc;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    border: 1px solid #0099dc;
  }
}

.mk-base-card-footer-element_1 > button {
  font-family: "Roboto", "Helvetica", "sans-serif";
  color: #fc6222 !important;
  font-weight: normal;
  height: 100% !important;
  width: 100% !important;
  // For Small Screen
  @media screen and (max-width: 799px) {
    box-shadow: 0px 2px 4px #0000001a !important;
    border: 0.5px solid #fc6222;
    border-radius: 5px;
    letter-spacing: 0.33px;
    font-size: 11px !important;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border: 1px solid #fc6222;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border: 1px solid #fc6222;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }
}

.mk-base-card-footer-element_2 > button {
  font-family: "Roboto", "Helvetica", "sans-serif";
  background: #0099dc 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  font-weight: normal;
  height: 100% !important;
  width: 100% !important;

  // For Small Screen
  @media screen and (max-width: 799px) {
    box-shadow: 0px 2px 4px #0000001a !important;
    border-radius: 5px;
    letter-spacing: 0.33px;
    font-size: 11px !important;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    box-shadow: 0px 5px 6px #00000026 !important;
    border-radius: 5px;
    letter-spacing: 0.54px;
    font-size: 18px !important;
  }
}
</style>
