<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0" :cols="colNum">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="12">
          <div class="mk-base-card-header-element_1 text-truncate">
            {{ title }}
          </div>
        </v-col>
        <v-col class="ma-0 pa-0" cols="12">
          <div class="mk-base-card-header-element_2 text-truncate">
            {{ availableDate }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="isProjectOrAssignment"
      class="ma-0 pa-0 d-flex justify-end"
      :cols="12 - colNum"
    >
      <div
        v-if="smallScreen"
        class="mk-base-card-header-element_3"
        @click="$emit('header_element_3')"
      >
        {{ btnName }}
      </div>
      <div v-else class="mk-base-card-header-element_3">
        <v-btn depressed tile plain @click="$emit('header_element_3')">
          {{ btnName }}
        </v-btn>
      </div>
    </v-col>
    <v-col
      v-else-if="isExam"
      class="ma-0 pa-0 d-flex justify-end"
      :cols="12 - colNum"
    >
      <div
        v-if="smallScreen"
        :style="
          shouldEnableElement_3
            ? { 'pointer-events': 'auto' }
            : { 'pointer-events': 'none', color: 'rgba(0, 0, 0, 0.5)' }
        "
        class="mk-base-card-header-element_3"
        @click="$emit('header_element_3')"
      >
        {{ btnName }}
      </div>
      <div v-else class="mk-base-card-header-element_3">
        <v-btn
          :disabled="!shouldEnableElement_3"
          depressed
          tile
          plain
          @click="$emit('header_element_3')"
        >
          {{ btnName }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MBaseCardHeaderNew",
  props: {
    title: String,
    availableDate: String,
    btnName: String,
    element_type: String,
    shouldEnableElement_3: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    smallScreen() {
      return this.currentWidth < 800 ? true : false;
    },

    isProjectOrAssignment() {
      return (
        this.element_type == "projcet" || this.element_type == "assignment"
      );
    },

    isExam() {
      return this.element_type == "exam";
    },

    colNum() {
      return this.currentWidth < 800
        ? 8
        : this.currentWidth < 1280
        ? 7
        : this.currentWidth < 1920
        ? 8
        : 8;
    },
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.mk-base-card-header-element_1 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  text-align: left;
  color: #000000;
  font-weight: bold;
  // For Small Screen
  @media screen and (max-width: 799px) {
    font-size: 10px;
    letter-spacing: 0px;
    padding-left: 17px;
    padding-top: 13px;
    max-width: 100%;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    font-size: 14px;
    letter-spacing: 0.28px;
    padding-left: 30px;
    padding-top: 22px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0.28px;
    padding-left: 22px;
    padding-top: 25px;
    max-width: 100%;
  }
}

.mk-base-card-header-element_2 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  text-align: left;
  color: #707070;
  font-weight: normal;
  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0.09px;
    font-size: 9px;
    padding-left: 17px;
    padding-top: 5px;
    max-width: 100%;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    letter-spacing: 0.12px;
    font-size: 12px;
    padding-left: 30px;
    padding-top: 3px;
    max-width: 100%;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    letter-spacing: 0.12px;
    font-size: 12px;
    padding-left: 22px;
    padding-top: 3px;
    max-width: 100%;
  }
}

.mk-base-card-header-element_3 {
  font-family: "Roboto", "Helvetica", "sans-serif";
  // For Small Screen
  @media screen and (max-width: 799px) {
    letter-spacing: 0px;
    color: #0099dc;
    font-size: 11px;
    text-align: right;
    text-decoration: underline;
    padding-top: 13px;
    padding-right: 13px;
    cursor: pointer;
  }

  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    height: 45px;
    width: 160px;
    margin-top: 16px;
    margin-right: 22px;
  }

  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    height: 45px;
    width: 160px;
    margin-top: 16px;
    margin-right: 22px;
  }
}

.mk-base-card-header-element_3 > button {
  height: 100% !important;
  width: 100% !important;
  // For Tab Screen and Large Screen
  @media screen and (max-width: 1279px) and (min-width: 800px),
    screen and (min-width: 1920px) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0099dc;
    border-radius: 5px;
    color: #0099dc !important;
    font-size: 16px;
  }
  //For Medium Screen
  @media screen and (max-width: 1919px) and (min-width: 1280px) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0099dc;
    border-radius: 5px;
    color: #0099dc !important;
    font-size: 16px;
  }
}
</style>
