var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mk-new-base-card",
      attrs: { outlined: "", width: _vm.cardWidth, height: _vm.cardHeight },
    },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            { staticClass: "ma-0 pa-0", attrs: { cols: "12" } },
            [_vm._t("header")],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            { staticClass: "ma-0 pa-0", attrs: { cols: "12" } },
            [_vm._t("middle-content")],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            { staticClass: "ma-0 pa-0", attrs: { cols: "12" } },
            [_vm._t("footer")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }